import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SktService {
  parentDetailsListSkt =[
    "test3 test3",
    "testparent",
    "test2 test2",
    "PARENT80 PARENT80",
    "SYED YOUSUF",
    "ISMAIL KHAN S",
    "PARENT80 PARENT80",
    "parents37 parents37",
    "TAJAMMUL HUSSAIN A",
    "part 15 part15",
    "ABDULLAH M",
    "THAJUDEEN ABUL HASSAN",
    "PARENT 56 PARENT56",
    "SYED I HUSSAIN",
    "SHEERIN RIZWANA VASILA",
    "PARENY678 LTD",
    "MOHAMMED ABDUL KASIM A",
    "PARENTS 43 PARENTS 43",
    "KALEEM BASHA",
    "SADIQ AHAMED",
    "SADIQ",
    "PARENT60 PARENT60",
    "PARENTS27 PARENTS27",
    "MOHD QUASIM",
    "parent7845828138 LTD",
    "SIRAJUDDIN Y",
    "IBRAHIM SAHIB P",
    "SHEIK ABDULLA A",
    "JAVEED BASHA K",
    "MOHAMMED ABDUR RAHMAN",
    "KAMARUDDIN M A H",
    "VASU J",
    "ANEES BASHA G",
    "SYED MOHAMED MOULANA J",
    "SALEEM S",
    "sameeha father b",
    "PARENTS30 PARENTS30",
    "PARENT67 PARENT67",
    "RIYAZ AHMAD",
    "PARENT80 PARENT80",
    "UMAR KATHAP",
    "PARENT17 PARENT17",
    "SHANAWAZ A",
    "SIRAJUDDIN",
    "NIL NIL",
    "KHAJA KAREEMULLAH N",
    "SEENI MOHAMED SATHATH M",
    "PARENT 41 PARENT 41",
    "PARENT51 PARENT51",
    "AKBAR SALIM H A",
    "SYED MASOOD M",
    "parent 36 parent36",
    "PARENTS5 PARENT5",
    "THASIN BASHA D",
    "ANWAR SATHIK S",
    "SIKKANDAR M",
    "SULTAN M J S",
    "MOHAMED HANIFA L",
    "MOHAMED ISMAIL",
    "SHAMS TABREZ R",
    "MOHAMMED SAMIULLAH",
    "ABDULLA M",
    "FAIZ MOHAMED KHAN",
    "ABDUL RAZAK M A K",
    "SYED AHAMED TAMEEM K",
    "VASEEM AHMED A",
    "KHADER MOHIDEEN N",
    "SYED HASSAIN",
    "KAREEM K H",
    "PART 40 PART40",
    "parent4 parent4",
    "KALVATH ABDUL KHADER M S",
    "SHAIK MOHAMMED YOUSUFF",
    "ABDUL SAMADU M",
    "SULAIMAN BASHA A",
    "MD FARHAN BADSHA SAYEED",
    "MOHAMMAD EHSAN",
    "MAHFUZUR RAHMAN",
    "QAMRUL ISLAM",
    "DAWOOD",
    "Unity Hussian",
    "MOHAMED ROBIK",
    "NADEEM ABDULLAH W",
    "MUNEER AHAMED G",
    "ABDULLAH",
    "ANWAR SADAT A P M",
    "MOHAMMAD SHABBIR ALAM",
    "SADIQ AHMED V S",
    "SHABEEK RAHUMAN A V",
    "MOHAMMED NAZEER HUSSAIN A K",
    "PARVEZ NASEER G M",
    "ABDUL RASHEED S",
    "AHAMED SHERIFF A",
    "MOHAMED HABIBULLAH S",
    "PARVEZ NASEER G M",
    "KHAJA MOHIDEEN M A",
    "MOHAMED ANWAR M I",
    "MUHAMMED ASLAM SHARIEF",
    "RAFFIC HUSSAIN M",
    "DR FATHIMA BANU",
    "MUJAHIDUL ISLAM",
    "MOHAMED IRSHADH S PARENT",
    "MOHAMED RAFIQ P",
    "PARENT68 PARENT68",
    "NAZIR AHAMED A",
    "MOHAMMED IBRAHIM.A",
    "MOHAMED ISMAIL",
    "THOULATTHULLA G M",
    "SYED ABDUL REHAMAN ZUBAIR",
    "JEYAKHAN A M",
    "ASAN MYDEEN R",
    "PARENT 38 PARENT 38",
    "SHEIK MUJBOOR RAHMAN",
    "KADHAR BASHA S K",
    "HAFEEZUR RAHMAN A B",
    "K THAMEEM ANSARI",
    "MUSHTAQ PASHA S J S",
    "MOHIDEEN ABDUL KHADER A R",
    "AL ALAHAPPHAN",
    "JEYA KHAN A M",
    "MOHAMED ILYAS K",
    "NAJEEB AHMED",
    "MOHAMED ALI M",
    "MOHIDEEN BOOSARI N M",
    "KHALEELUR RAHMAN M",
    "BASHIR AHMED M",
    "NAVASKHAN SAMSURALI",
    "SYED NASEER AHMED",
    "MUSHTAQ PASHA S.J.S",
    "ABUL HASAN SHADULY M",
    "SHIHABUDHEEN MALIYEKKAZ DR",
    "MOHAMMED NAZEER HUSSAIN",
    "FARHANA SHIREEN A PARENT",
    "MUHAMMED MUBEEN A",
    "PARENT55 PARENT55",
    "SHAFFI AHMED M",
    "PARENT PARENT",
    "VINAYAGAMOORTHY M",
    "ABDUL TAHIRUR RAHMAN",
    "SYED FAKHIR MOHIUDEEN",
    "NISAR AHMED A",
    "ABDUR RAHMAN R",
    "NAZIR AHAMED A",
    "janarthana kumar",
    "RASOOL MOHIDEEN S A",
    "SYED RAHMATHULLA R",
    "MERCURY CAR RENTALS LTD",
    "TAMIMOUL ANSARI S",
    "AHAMED HUSSAIN M D",
    "MUJEE UR REHEMAN B",
    "MOHAMAD JAKIR HUSSAIN S",
    "SYED ABDUL KHADER I",
    "MUJEE UR REHEMAN B",
    "ABUBAKKAR T",
    "IRSHAD SHARIFF",
    "SHEIK MOHAMMED J",
    "AMEERDEEN Y",
    "SYED MOHAMED BUHARI S S",
    "MOHAMMAD ABID ALI",
    "ASKAR ALI KHAN H",
    "AYUB KHAN A",
    "MOHIDEEN NATHHAR",
    "NOOR MOHAMED",
    "PARENT58 PARENT58",
    "KADAR MOHIDEEN",
    "BAHRUDEEN Z",
    "abdul",
    "MOHAMMED ASLAM I",
    "ABDUL KAREEM K K",
    "Kader Mohideen M",
    "MUHAMMAD MOHIDEEN MARICAR",
    "NOOR MOHAMED",
    "HUSNE MUBARAK A",
    "MADHEENA",
    "RAHIM SHARIFF D",
    "MOOSAL KALIM M A",
    "VASU J",
    "BADHURDEEN B A",
    "THALIF ALI A",
    "SHAIK ISMAIL",
    "ZULFIQARALI KAMAL",
    "AHAMED MANJUR MOHAMED IBRAHIM",
    "PARENT 40 PARENT40",
    "PARENTS 85 PARENTS 85",
    "SADIQ ALI K",
    "PARENT26 PARENT26",
    "nil nil",
    "FAQRUDIN M",
    "ABDUL HUQ RAMEEZ",
    "SYED ALI I",
    "HAFEEZ A H",
    "PARENT34 PARENT34",
    "NAZEERULLAH A",
    "ABDUL SHAHID",
    "SHAMSUDDEEN K P",
    "JAMALUDEEN N",
    "PARENT8 PARENT8",
    "parent2 parent2",
    "ASHIK ALI J",
    "MOHAMMED RAFI S",
    "AHMED IBRAHIM BILAL T",
    "SEENI IBRAM SHA",
    "RAFI MOHAMED",
    "SAFIYUR RAHMAN",
    "PARENT77 PARENT77",
    "ASAN MOHIDEEN Y",
    "SUHAIL K",
    "ABDUL HAKEEM K",
    "AHMED MUDASSAR ALI",
    "ABDUL SAHIB K",
    "ABOOBUCKER JALALUDEEN R",
    "MOHAMMED THAMEEM ANSARI M",
    "PARENT 85 PARENT 85",
    "NIJAMUDEEN",
    "SHAMSUDDIN K F",
    "BAIJUL MILLA Y",
    "SYED HABBAS H",
    "SHAMEEM B M",
    "ANWARDEEN H",
    "MOHAMED EBRAHIM A",
    "JAFARULLA KHAN M S A",
    "MOHAMED JAHAN G",
    "SHEIK MUJEEPUR RAHMAN S",
    "MANOHAR M",
    "PARENT36 PARENT36",
    "IMTHIAZ M K",
    "UBAIDULLAH S",
    "JAFFER ALI S",
    "A MOHAMMED IBRAHIM",
    "PARENT21 PARENT21",
    "MOHAMED IBRAHIM S M",
    "KADHAR MASTHAN K M",
    "SOUKATHALI",
    "ABDUL HAMEED N",
    "PART56 PART56",
    "AHAMED ALI S",
    "SHAIK MOHAMED YOUSUF M S",
    "RAFIQ AHMED S",
    "ZIAULLAH R",
    "MUHAMMAD YUSUF",
    "ABDUL RAVOOF R",
    "YASSER AURAFATH R R",
    "BAHRUDEEN",
    "ZACKIR HUSSAI K",
    "MOHAMED ASAD",
    "KAMALUDEEN",
    "ISMAIL KHAN S",
    "PARENT68 PARENT68",
    "GHOUSE BASHA",
    "MOHAMED ZAHURUDDIN S",
    "JAFFAR ALI A",
    "NAINAR FIRTHOUSE ALI",
    "SHANAWAZ A",
    "SYED NAINA MOHAMED",
    "SYED IBRAHIM",
    "MOHAMMED FAISAL ZULFUQUAR",
    "SAMSUDIN J",
    "ANSARI A Y",
    "MOHAMMAD KHASIM G",
    "NADUVILOTHI SARIN",
    "AHMED BASHA A",
    "MOHAMED SHERIF S",
    "JAFEER MEERAN B",
    "KISAR MOHAMED K",
    "MUHAMADU KAMALUDEEN A",
    "MOHAMED YUSUF A",
    "ASHOK M",
    "IRFAN BUHARI C M",
    "YAASEEN MASVOOD",
    "parent009 LTD",
    "PARENT 44 PARENT44",
    "JAKIR HUSSAIN",
    "SAMIULLAH KHAN",
    "MEERA MOHIDEEN H",
    "KADER MYDEEN",
    "PARENT33 PARENT33",
    "NIZAMDEEN",
    "MOHAMED JAMAL",
    "GHOUSE AHMED K",
    "ABDULLAH M",
    "FARHAN K",
    "MOHAMED NOORUL AMEEN S",
    "ABDUL MUBARAK S",
    "MOHAMMAD RAFFIK RAJA M A",
    "PARENT 47 PARENT47",
    "MOHAMMED GHOUSE",
    "BEER A P",
    "ALIAKBAR",
    "ABDUL RAHIM P A",
    "PARENT54 PARENT54",
    "Nisar Ahmed Shariff Nisar Ahmed Shariff",
    "PS12 PS12",
    "SHAIK KADIR ABDUL",
    "ASHFAQ AHAMED R",
    "MOHAMED MOHIDEEN",
    "PARENT65 PARENT65",
    "AFZAL KHAN K",
    "M N SHEIK UDUMAN",
    "AHAMED BASHEER K K",
    "MOHIDEEN GANI A",
    "ABDUL KADHER",
    "FAROOK N",
    "PARENT11 LTD",
    "JAVID KHAN N",
    "parent567 LTD",
    "SHAHUL HAMEED SHAJAHAN",
    "USNE MUBARAK A",
    "MANSOOR ALI R",
    "SHABEEK RAHMAN A V",
    "ABDUL RAJAK M G",
    "PARENT 79 PARENT 79",
    "KADER MUHIDEEN",
    "MOHAMED ABDUL CADER",
   "MOHAMED IRSHAD",
    "SHAHUL HAMEED M",
    "PARENT58 PARENT58",
    "DR.M.MOHAMMED SALAMULLAH",
    "IRSHAD KHALEEL",
    "OMAR SHERIFF A",
    "SHAHUL HAMED B",
    "PARENT 59 PARENT59",
    "RAYEES T K",
    "GHOUSE MOHAMED M H",
    "MOHAMMED NIVAS T",
    "SHEIK ABUBAKKER SIDDIG S",
    "FAYAZ AHAMED K",
    "PARENT22 PARENT22",
    "PARENT 50 PARENT 50",
    "MOHAMMED ABDUL AZEEM",
    "ABDUL KHADER SULAIMAN",
    "ZULFIQARALI K B",
    "MOHAMED ARIF PASHA A",
    "ABDULLAH BILAL",
    "AJMEER KHAN K",
    "MOHAMED ARIF PASHA A",
    "PARENTS24 PARENTS24",
    "RYAS AHAMED A M",
    "THAMEEMUL ANSARI M Y",
    "MOHAMED SHAHUL HAMEED",
    "SYED ALI S",
    "SHOUJIK MOHAMED",
    "PARENTS 38 PARENTS 38",
    "MOHAMMAD NIYAZ M",
    "SHAHUL HAMEED S",
    "MIRNA SAINAF PARENT",
    "SIRAJUDEEN J",
    "SYED LIYAKATH ALI",
    "SHAHUL HAMEED H",
    "ABDUL MAJEED A",
    "PARENT 72 PARENT72",
    "SAIFULLAH",
    "FAROOK MEERAN M",
    "PARENTS16 PARENTS16",
    "MOHAMED SHAHUL HAMEED SHERIFF S K",
    "MOHAMMED HASSAN MARICAR",
    "SHANNAWAZ A",
    "MOHAMED IQBAL H",
    "MOHAMED IQBAL",
    "BALAMURUGAN",
    "MOHAMED ALI",
    "SADAKUTHULLAH S",
    "PARENT57 PARENT57",
    "NOOR MOHAMED A",
    "MUHAMED RIYAZ M",
    "parents17 parents17",
    "MUBARAQ ALI k",
    "SYED SAIT",
    "MOHAMED RAFI K M",
    "parent567 LTD",
    "MOHAIDEEN ABDUL KHADER",
    "NAVASKHAN SAMSUR ALI",
    "PARENT57 PARENT57",
    "parent20 parent20",
    "MD IMTIAZ AHMAD KHAN",
    "MOHAMED ZACKRIA M",
    "partent24 parent24",
    "BAABJAN B",
    "MOHAMMED SAMIYULLAH R",
    "NIL NIL",
    "RIYAZ AHAMED F",
    "MOHAMED SABIK",
    "ANWAR SADATH",
    "PARENT 46 PARENT 46",
    "MOHAMED ARSHADU ALI M S",
    "MOHAMMED IMRAN PATEL",
    "MOHAMED ABDUL HAKEEM S",
    "RIYAZ AHAMED P",
    "SYED LIYAKATH ALI",
    "MOHAMED SEHU OLI",
    "parent9994161939 LTD",
  ];

selecttagidSkt=[
  '8220000002','8220000003','8220000004','8220000005','8220000006',
  '8220000007','8220000008','8220000009','8220000010','8220000012','8220000014','8220000018','8220000047',
  '8220000080','8220000088','8220000092','8220000097','8220000098','8220000103','8220000107','8220000108',
  '8220000109','8220000110','8220000112','8220000114','8220000126','8220000133','8220000136','8220000140',
  '8220000141','8220000143','8220000144','8220000147','8220000148','8220000149','8220000156','8220000168',
  '8220000169','8220000174','8220000176','8220000185','8220000191','8220000194','8220000195','8220000196',
  '8220000197','8220000201', '8220000209','8220000210','8220000211','8220000212','8220000215','8220000223',
  '8220000224','8220000225','8220000226','8220000232','8220000233','8220000244','8220000247', '8220000248',
  '8220000252','8220000253','8220000254','8220000259','8220000261','8220000262','8220000263','8220000265',
  '8220000266','8220000267','8220000270','8220000275','8220000279','8220000280','8220000281','8220000282',
  '8220000287','8220000289','8220000290','8220000291','8220000292','8220000293','8220000294','8220000295',
  '8220000296','8220000297','8220000298','8220000299','8220000301','8220000302','8220000303','8220000304',
  '8220000305','8220000309','8220000320','8220000322','8220000327','8220000328','8220000334','8220000335',
  '8220000337','8220000338','8220000339','8220000340','8220000342','8220000343','8220000344','8220000347',
  '8220000348','8220000349','8220000350','8220000352','8220000354','8220000355','8220000356','8220000357',
  '8220000358','8220000359','8220000360','8220000364','8220000369','8220000372','8220000374','8220000381',
  '8220000385','8220000386','8220000387','8220000388','8220000392','8220000393','8220000395','8220000396',
  '8220000397','8220000398','8220000399','8220000400','8220000401','8220000402','8220000403','8220000404',
  '8220000405','8220000406','8220000407','8220000408','8220000413','8220000422','8220000423','8220000424',
  '8220000426','8220000427','8220000432','8220000439','8220000440','8220000529','8220003029','8220004529',
  '8220006729','8220007829','8220008007','8220067823','8220087829','8220467823','8220603029','8440000543',
  '8440000564','8440000586','8440000590','8440000593', '8440000595','8440000596','8440000611','8440000643'
];
classidSkt=[
'I','II','III','IV','IX','LKG','STD I','STD II','STD III','STD IV','STD IX','STD V','STD VI','STD VII','STD VIII',
'STD X','STD XI-GROUP1','STD XI-GROUP2','STD XI-GROUP3','STD XI-GROUP4','STD XI-GROUP5','STD XII-GROUP1','STD XII-GROUP2',
'STD XII-GROUP3','STD XII-GROUP4','STD XII-GROUP5','UKG','V','VI','VII','VIII','X','XI-1','XI-2','XI-3','XI-4',
'XI-5','XII-1','XII-2','XII-5',
];
pickuprouteSkt=[
  'BUS-B','BUS-C','BUS-D','BUS-E','BUS-F','BUS-H','BUS-I','BUS-K','BUS-N','BUS-R','BUS-S','undefined','BUS-V'
];
alertoptionSkt=[
  'Sms','Mail','Both','None'
];
mapoptionSkt=[
  'Circle','Rectangle','FreeForm'
];
droprouteSkt=[
'BUS-B','BUS-C','BUS-D','BUS-DG','BUS-E','BUS-F','BUS-H','BUS-I','BUS-K','BUS-N','BUS-R','BUS-S','undefined','BUS-V'
];

objSkt=[ 
  {
    'userId':'9442972366',
    'roll':'parent',
    'parentName':'Rahamathulla',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'rahamath43@gmail.com',
    'contactno':'9442972366',
    
  },
  {
    'userId':'8122991988',
    'roll':'parent',
    'parentName':'Azeem',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'azeembasha@gmail.com',
    'contactno':'8122991988'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  {
    'userId':'9500243158',
    'roll':'parent',
    'parentName':'Riyaz',
    'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
    'emailaddress':'riyaz@gmail.com',
    'contactno':'9500243158'
  },
  
] 
objeSkt=[
  {
  'rollno':'001',
  'firstname':'Mubeen',
  'lastname': "",
  'tagid':'8222310045',
  'gender':'female',
  'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
  'class':'IX',
  'section':'BOY',
  'contactno':'8122991988'
},
{
  'rollno':'002',
  'firstname':'Rahamathulla',
  'lastname': "",
  'tagid':'8222310045',
  'gender':'Male',
  'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
  'class':'X',
  'section':'GA',
  'contactno':'8122991988'
},
{
  'rollno':'003',
  'firstname':'Malar',
  'lastname': "",
  'tagid':'8222310045',
  'gender':'Male',
  'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
  'class':'X',
  'section':'GA',
  'contactno':'8122991988'
},
{
  'rollno':'004',
  'firstname':'Rahamath',
  'lastname': "",
  'tagid':'8222310045',
  'gender':'Male',
  'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
  'class':'X',
  'section':'GA',
  'contactno':'8122991988'
},
{
  'rollno':'005',
  'firstname':'Rahamath',
  'lastname': "",
  'tagid':'8222310045',
  'gender':'Male',
  'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
  'class':'X',
  'section':'GA',
  'contactno':'8122991988'
},
{
  'rollno':'006',
  'firstname':'Rahamath',
  'lastname': "",
  'tagid':'8222310045',
  'gender':'Male',
  'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
  'class':'X',
  'section':'GA',
  'contactno':'8122991988'
},
{
  'rollno':'007',
  'firstname':'Rahamath',
  'lastname': "",
  'tagid':'8222310045',
  'gender':'Male',
  'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
  'class':'X',
  'section':'GA',
  'contactno':'8122991988'
},
{
  'rollno':'008',
  'firstname':'Rahamath',
  'lastname': "",
  'tagid':'8222310045',
  'gender':'Male',
  'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
  'class':'X',
  'section':'GA',
  'contactno':'8122991988'
},
{
  'rollno':'009',
  'firstname':'Rahamath',
  'lastname': "",
  'tagid':'8222310045',
  'gender':'Male',
  'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
  'class':'X',
  'section':'GA',
  'contactno':'8122991988'
},
{
  'rollno':'0010',
  'firstname':'Rahamath',
  'lastname': "",
  'tagid':'8222310045',
  'gender':'Male',
  'address':'Flat No.8,Azhagarperumal kovil street,Choolaimedu',
  'class':'X',
  'section':'GA',
  'contactno':'8122991988'
},
] 
objtag=[
  {
  'tagId':'80009000124',
  'tagType':'Passive',
  'associateStu':'AAminaFathima',
  'class':'STD XI-GROUP1',
  'section':'A'
 },
 {
    'tagId':'9800090001',
    'tagType':'Passive',
    'associateStu':'Sameena',
    'class':'STD X',
    'section':'B'
},
{
    'tagId':'8000900012',
    'tagType':'Passive',
    'associateStu':'Farhana',
    'class':'STD VI',
    'section':'B'
},
]
  gateSkt=[
    {
      'gateId':'1231',
      'gatename':'Tamarai',
      'location':'324/2222,Poongodai Nagar,Chennai',
      'description':'is assignable to parameter of type ',
      'deviceIMEI':'5434567823'
    },
    {
      'gateId':'1231',
      'gatename':'Tamarai',
      'location':'324/2222,Poongodai Nagar,Chennai',
      'description':'is assignable to parameter of type ',
      'deviceIMEI':'5434567823'
    },
    {
      'gateId':'1231',
      'gatename':'Tamarai',
      'location':'324/2222,Poongodai Nagar,Chennai',
      'description':'is  assignable to parameter of type ',
      'deviceIMEI':'5434567823'
    }
]

schoolEnable =[
  {
  'id':'564367',
  'fromDate':'2021-03-22',
  'toDate':'2021-05-02',
  'type':'Enable',
  'reason':'gfdgsdhhs',
  'single':"true",
  'multiple':"false"
},
{
  'id':'564367',
  'fromDate':'2021-03-22',
  'toDate':'2021-05-02',
  'type':'Enable',
  'reason':'gfdgsdhhs',
  'single':"true",
  'multiple':"false"
},
{
  'id':'564367',
  'fromDate':'2021-03-22',
  'toDate':'2021-05-02',
  'type':'Enable',
  'reason':'gfdgsdhhs',
  'single':"true",
  'multiple':"false"
},{
  'id':'564367',
  'fromDate':'2021-03-22',
  'toDate':'2021-05-02',
  'type':'Enable',
  'reason':'gfdgsdhhs',
  'single':"true",
  'multiple':"false"
},{
  'id':'564367',
  'fromDate':'2021-03-22',
  'toDate':'2021-05-02',
  'type':'Enable',
  'reason':'gfdgsdhhs',
  'single':"true",
  'multiple':"false"
},
]
routeName=["BUS-B","BUS-C","BUS-D","BUS-DG","BUS-E","BUS-F","BUS-H","BUS-I","BUS-K","BUS-N","BUS-R","BUS-S","undefined","BUS-V"]
vehicleNumber=["TN 07 BH 4603-spare","TN 22 AV 7123"]
trip =["Pickup","Drop","Wokeup"]


data=[
  {
    'tripName':'Morning',
    'tripId':'2312',
    'tripStartTime':'07:00:00',
    'tripEndTime':'05:00:00',
    'type':'Pickup',
  },
  {
    'tripName':'Afternoon',
    'tripId':'2312',
    'tripStartTime':'06:00:00',
    'tripEndTime':'05:00:00',
    'type':'Pickup',
  },
  {
    'tripName':'Evening',
    'tripId':'2312',
    'tripStartTime':'05:00:00',
    'tripEndTime':'05:00:00',
    'type':'Pickup',
  },
  {
    'tripName':'Morning',
    'tripId':'2312',
    'tripStartTime':'05:00:00',
    'tripEndTime':'05:00:00',
    'type':'Pickup',
  },
  {
    'tripName':'Morning',
    'tripId':'2312',
    'tripStartTime':'05:00:00',
    'tripEndTime':'05:00:00',
    'type':'Pickup',
  },
  {
    'tripName':'Morning',
    'tripId':'2312',
    'tripStartTime':'05:00:00',
    'tripEndTime':'05:00:00',
    'type':'Pickup',
  },
  {
    'tripName':'Morning',
    'tripId':'2312',
    'tripStartTime':'05:00:00',
    'tripEndTime':'05:00:00',
    'type':'Pickup',
  },
  {
    'tripName':'Morning',
    'tripId':'2312',
    'tripStartTime':'05:00:00',
    'tripEndTime':'05:00:00',
    'type':'Pickup',
  },
  {
    'tripName':'Morning',
    'tripId':'2312',
    'tripStartTime':'05:00:00',
    'tripEndTime':'05:00:00',
    'type':'Pickup',
  },
  {
    'tripName':'Morning',
    'tripId':'2312',
    'tripStartTime':'05:00:00',
    'tripEndTime':'05:00:00',
    'type':'Pickup',
  },
]
broadOdj =[
  {
    'id':'12345',
    'companyid':'456754',
    'branchid' :'5456666',
    'userid'   :'53453453',
    'category' :'gdhdfh',
    'misc'     : 'gjhgfjf',
    'fmobile'  : 'hfhdfhhgf',
    'tmobile'  : 'gjgjgjg',
    'msg'      :'uygcvj',
    'msgtype'  :'nbbvnvbm',
    'etime'    : 'ghgfjgfj',
    'stime'    :'bvncvncv'

},
{
  'id':'12345',
  'companyid':'456754',
  'branchid' :'5456666',
  'userid'   :'53453453',
  'category' :'gdhdfh',
  'misc'     : 'gjhgfjf',
  'fmobile'  : 'hfhdfhhgf',
  'tmobile'  : 'gjgjgjg',
  'msg'      :'uygcvj',
  'msgtype'  :'nbbvnvbm',
  'etime'    : 'ghgfjgfj',
  'stime'    :'bvncvncv'

},
{
'id':'12345',
'companyid':'456754',
'branchid' :'5456666',
'userid'   :'53453453',
'category' :'gdhdfh',
'misc'     : 'gjhgfjf',
'fmobile'  : 'hfhdfhhgf',
'tmobile'  : 'gjgjgjg',
'msg'      :'uygcvj',
'msgtype'  :'nbbvnvbm',
'etime'    : 'ghgfjgfj',
'stime'    :'bvncvncv'

},
]

//                 <ion-row>
//                   <ion-col>Route name</ion-col>
//                   <ion-col> AH1</ion-col>
students=[
  {
    'studentName'    :'Javid Khan',
    'section'        :'A',
    'standard'       :'III',
    'driverName'     :'Abdullah',
    'driverContact'  :8122991988,
    'attenderName'   :'Riyaz',
    'attenderContact':9840158425,
    'plateNumber'    :'TN 24 03214',
    'boardingStatus' :'Stared at Home',
    'routeName'      : 'AH1'
  },
  {
    'studentName'    :'Imran',
    'section'        :'C',
    'standard'       :'IX',
    'driverName'     :'Abdullah',
    'driverContact'  :8122991988,
    'attenderName'   :'Riyaz',
    'attenderContact':9840158425,
    'plateNumber'    :'TN 24 03214',
    'boardingStatus' :'Reached school',
    'routeName'      : 'NH40'
  },
  {
    'studentName'    :'Jailani Yasar M',
    'section'        :'A',
    'standard'       :'X',
    'driverName'     :'Abdullah',
    'driverContact'  : 8122991988,
    'attenderName'   :'Riyaz',
    'attenderContact': 9840158425,
    'plateNumber'    :'TN 24 03214',
    'boardingStatus' :'On board',
    'routeName'      : 'NH7'
  },
  {
    'studentName'    :'Gokul',
    'section'        :'A',
    'standard'       :'XI',
    'driverName'     :'Abdullah',
    'driverContact'  :8122991988,
    'attenderName'   :'Riyaz',
    'attenderContact': 9840158425,
    'plateNumber'    :'TN 24 03214',
    'boardingStatus' :'Reached school',
    'routeName'      : 'AH2'
  },
  {
    'studentName'    :'Javid Khan',
    'section'        :'A',
    'standard'       :'III',
    'driverName'     :'Abdullah',
    'driverContact'  :8122991988,
    'attenderName'   :'Riyaz',
    'attenderContact':9840158425,
    'plateNumber'    :'TN 24 03214',
    'boardingStatus' :'Stared at Home',
    'routeName'      : 'AH3'
  },
  {
    'studentName'    :'Imran',
    'section'        :'C',
    'standard'       :'IX',
    'driverName'     :'Abdullah',
    'driverContact'  :8122991988,
    'attenderName'   :'Riyaz',
    'attenderContact':9840158425,
    'plateNumber'    :'TN 24 03214',
    'boardingStatus' :'Reached school',
    'routeName'      : 'AH1'
  },
  {
    'studentName'    :'Jailani Yasar M',
    'section'        :'A',
    'standard'       :'X',
    'driverName'     :'Abdullah',
    'driverContact'  : 8122991988,
    'attenderName'   :'Riyaz',
    'attenderContact': 9840158425,
    'plateNumber'    :'TN 24 03214',
    'boardingStatus' :'Stared at Home',
    'routeName'      : 'AH1'
  },
  {
    'studentName'    :'Gokul',
    'section'        :'A',
    'standard'       :'XI',
    'driverName'     :'Abdullah',
    'driverContact'  :8122991988,
    'attenderName'   :'Riyaz',
    'attenderContact': 9840158425,
    'plateNumber'    :'TN 24 03214',
    'boardingStatus' :'Reached school',
    'routeName'      : 'AH1'
  },
  {
    'studentName'    :'Imran',
    'section'        :'C',
    'standard'       :'IX',
    'driverName'     :'Abdullah',
    'driverContact'  :8122991988,
    'attenderName'   :'Riyaz',
    'attenderContact':9840158425,
    'plateNumber'    :'TN 24 03214',
    'boardingStatus' :'On board',
    'routeName'      : 'AH1'
  },
  {
    'studentName'    :'Jailani Yasar M',
    'section'        :'A',
    'standard'       :'X',
    'driverName'     :'Abdullah',
    'driverContact'  : 8122991988,
    'attenderName'   :'Riyaz',
    'attenderContact': 9840158425,
    'plateNumber'    :'TN 24 03214',
    'boardingStatus' :'Stared at Home',
    'routeName'      : 'AH1'
  },
  {
    'studentName'    :'Gokul',
    'section'        :'A',
    'standard'       :'XI',
    'driverName'     :'Abdullah',
    'driverContact'  :8122991988,
    'attenderName'   :'Riyaz',
    'attenderContact': 9840158425,
    'plateNumber'    :'TN 24 03214',
    'boardingStatus' :'On board',
    'routeName'      : 'AH1'
  },
]
}

