import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Location } from '@angular/common';
import { Platform } from '@ionic/angular';
import { companyReportData } from 'src/environments/environment';

@Component({
  selector: 'app-alert-report',
  templateUrl: './alert-report.component.html',
  styleUrls: ['./alert-report.component.scss'],
})
export class AlertReportComponent implements OnInit {
  alert;
  view = 'grid'
  pdfHead: any = ['Plate No', 'Operator Name', 'Alert Types', 'Date & Time'];
  constructor(
    private location: Location,
    private commonService: CommonService,
    private platform: Platform
  ) { }

  createPdf() {
    var obj = [];
    for (let i = 0; i < this.alert.length; i++) {
      obj.push([this.alert[i].plateNo, this.alert[i].operatorName,
      this.alert[i].alertTypes, this.alert[i].timeStamp])
    }
    this.commonService.downloadPdfReports(this.pdfHead, obj, "Alert report")
  }

  typeOfView(viewType: string) {
    this.view = viewType;
  }

  getBack() {
    this.location.back();
  }
  ngOnInit() {
    this.alert = companyReportData.reportData;
    this.commonService.dismissLoader();
  }
}

