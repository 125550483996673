import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { AuthMapService } from "src/app/services/auth-map.service";
import { OpenlayerMapService } from "src/app/services/openlayer-map.service";
import { toLonLat } from "ol/proj";
import { ModalController } from "@ionic/angular";
import { AjaxService } from "src/app/services/ajax.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-latlong",
  templateUrl: "./latlong.component.html",
  styleUrls: ["./latlong.component.scss"],
})
export class LatlongComponent implements OnInit {
  @Input() currentLocation;
  map;
  @ViewChild("mapElement", { static: false }) mapElement;
  latitude: any;
  longitude: any;
  range = 500;

  constructor(
    public mapService: AuthMapService,
    public geolocation: Geolocation,
    private modelController: ModalController,
    public ajaxService: AjaxService,
    public commonService: CommonService
  ) {
    this.mapService = new OpenlayerMapService();
  }
  getAddressLatlng(address) {
    if (address != "") {
      let data: any = parseFloat(address.split(",")[0]);
      if (data.toString() != "NaN") {
        if (address.split(",")[1]) {
          this.mapService.setCenter(this.map, {
            lat: parseFloat(address.split(",")[0]),
            lng: parseFloat(address.split(",")[1]),
          });
        }
      } else if (data.toString() == "NaN") {
        this.ajaxService
          .ajaxGetwithouttoken(
            "https://nominatim.openstreetmap.org/?format=json&addressdetails=1&q=" +
              address +
              "&format=json&limit=1"
          )
          .subscribe((res) => {
            if (res.length > 0) {
              let latLng = { lat: res[0].lat, lng: res[0].lon };
              this.mapService.setCenter(this.map, {
                lat: parseFloat(res[0].lat),
                lng: parseFloat(res[0].lon),
              });
            } else
              this.commonService.presentToast(
                "Can't able to get your location"
              );
          });
      }
    }
  }
  submit() {
    this.modelController.dismiss();
  }
  // getBack() {
  //   const geoBounds = this.mapService.circleGeoZone();
  //   localStorage.setItem("latLongPickerGeoFence", geoBounds)
  //   this.modelController.dismiss();
  // }

  cancelBtn() {
    this.modelController.dismiss();
  }

  setRange(range) {
    this.range = range;
    this.mapService.setCircleRadius(this.range);
  }

  createMap() {
    const defaultLocation: any = localStorage.getItem("defaultLocation");
    const [latitudeStr, longitudeStr]: string[] = defaultLocation ? defaultLocation.split(',') : " ";
    const latitude: number = parseFloat(latitudeStr);
    const longitude: number = parseFloat(longitudeStr);       
    let  myLatlng = { lat: latitude, lng: longitude };

    this.map = this.mapService.loadMap(
      this.mapElement.nativeElement,
      myLatlng,
      false,
      false
    );
    this.mapService.setCenter(this.map, myLatlng);
    this.onClickLocationGetter();
    if (this.currentLocation == "") {
      this.geolocation
        .getCurrentPosition()
        .then((resp) => {
          this.mapService.setCenter(this.map, {
            lat: parseInt(resp.coords.latitude.toFixed(2)),
            lng: parseInt(resp.coords.longitude.toFixed(2)),
          });
          this.latitude = resp.coords.latitude.toFixed(2);
          this.longitude = resp.coords.longitude.toFixed(2);
          this.mapService.addMarker(
            this.map,
            { lat: resp.coords.latitude, lng: resp.coords.longitude },
            "LatLngPicker",
            "assets/vtstrackhisIcon/Idle.png"
          );
          this.mapService.createCircle(
            this.map,
            { lat: resp.coords.latitude, lng: resp.coords.longitude },
            this.range,
            "Preferred"
          );
          this.mapService.fitBounds(this.map, 2);
        })
        .catch((error) => {});
    } else {
      this.latitude = parseFloat(this.currentLocation.split(",")[0]).toFixed(2);
      this.longitude = parseFloat(this.currentLocation.split(",")[1]).toFixed(
        2
      );
      this.mapService.addMarker(
        this.map,
        {
          lat: parseFloat(this.currentLocation.split(",")[0]),
          lng: parseFloat(this.currentLocation.split(",")[1]),
        },
        "LatLngPicker",
        "assets/vtstrackhisIcon/Idle.png"
      );
    }
  }

  onClickLocationGetter() {
    this.map.on("click", async (evt) => {
      this.mapService.clearLayers(this.map, [1, 2]);
      const coordinates = evt.coordinate;
      let latLng = toLonLat([coordinates[0], coordinates[1]]);
      this.latitude = latLng[1].toFixed(2);
      this.longitude = latLng[0].toFixed(2);
      latLng = { lat: latLng[1], lng: latLng[0] };
      localStorage.setItem("mapLocationPicker", latLng.lat + "," + latLng.lng);
      this.mapService.addMarker(
        this.map,
        latLng,
        "updateZone",
        "assets/vtstrackhisIcon/Idle.png"
      );
      this.mapService.createCircle(this.map, latLng, 500, "Preferred");
      const geoBounds = this.mapService.circleGeoZone();
      localStorage.setItem("latLongPickerGeoFence", geoBounds);
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.createMap();
    }, 2000);
  }
}
