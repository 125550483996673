import { Component, OnInit, Input } from "@angular/core";
import { AjaxService } from "../../services/ajax.service";
import { serverUrl, app, storageVariable, languageInitializer, } from "../../../environments/environment";
import { GeofenceModelPage } from "../../geofence/geofence-model/geofence-model.page";
import { GeofenceListModelPage } from "../../geofence/geofence-list-model/geofence-list-model.page";
import { ModalController, MenuController, Platform, AlertController, } from "@ionic/angular";
import { CommonService } from "src/app/services/common.service";
import { ActivatedRoute } from "@angular/router";
import { GeofenceAssociationModelComponent } from "../geofence-association-model/geofence-association-model.component";
import { TranslateService } from "@ngx-translate/core";
import { TranslateConfigService } from "../../services/translate-config.service";

@Component({
  selector: "app-vts-geofence",
  templateUrl: "./vts-geofence.component.html",
  styleUrls: ["./vts-geofence.component.scss"],
})
export class VtsGeofenceComponent implements OnInit {
  geoFenceJson = [];
  geoFenceJsonAll = [];
  directory = languageInitializer.directory;
  index = 0;
  zoneType: string = "prefer";
  selectedVin;
  selectedZoneData = [];
  selectItem = true;
  type: string;
  filterValue: any;
  subscription: any;
  @Input() vinGrid;
  @Input() gridLiveChange;
  app: any = { logo: "logo.png" };
  appName: string;
  selectedGeofenceVin: any;
  selectedGeofenceVinData: any;
  deletezone: any = [];
  associatedGeofenceList: any = [];
  entryLogin: string = localStorage.entryPoint;
  constructor(
    private ajaxService: AjaxService,
    private modalController: ModalController,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private menuController: MenuController,
    private platform: Platform,
    private alertController: AlertController,
    public translateConfigService: TranslateConfigService,
    public trans: TranslateService
  ) { }

  async openGeoFenceAssociation() {
    let modal = await this.modalController.create({
      component: GeofenceAssociationModelComponent,
      cssClass: "geoAssociation",
    });
    return await modal.present();
  }

  ngOnInit() {
    this.app["logo"] = localStorage.companyLogo;
    this.appName = app.appName;
    if (this.vinGrid) {
      this.filterValue = "vin";
    } else {
      this.filterValue =
        this.filterValue == null
          ? "All"
          : this.activatedRoute.snapshot.paramMap.get("type");
    }
    this.getAssociatedVehicleDatas();
    this.getGeoFenceData(this.zoneType);
    //   this.selectedVin = JSON.parse(localStorage.getItem('selectedVin'));
    //   const modal = await this.modalController.create({
    //     component: GeofenceModelPage,
    //     componentProps: {
    //       'mode': 'add',
    //       'vin': this.selectedVin.vin,
    //       'vinData': this.selectedVin
    //     }
    //   });
    // return await modal.present();
  }

  refreshGeoFence() {
    this.getGeoFenceData(this.zoneType);
    this.getAssociatedVehicleDatas();
  }

  ionViewWillEnter() {
    this.getGeoFenceData(this.zoneType);
    this.getAssociatedVehicleDatas();
  }
  ngOnChanges() {
    this.getGeoFenceData(this.zoneType);
    this.getAssociatedVehicleDatas();
  }

  getAssociatedVehicleDatas() {
    var url = serverUrl.web + "/alert/AssociateZone/" + localStorage.userName;
    this.ajaxService.ajaxGetJson(url).subscribe((res) => {
      if (res.length > 0) {
        this.associatedGeofenceList = res;
      }
    });

  }

  getGeoFenceData(data) {
    this.commonService.presentLoader();
    this.zoneType = data;
    let selectedVin = "";
    if (this.filterValue != "All")
      selectedVin = JSON.parse(localStorage.getItem("selectedVin")).vin;
    const body = {
      companyID: localStorage.corpId,
      branchID: localStorage.corpId,
      userID: localStorage.userName,
      area: "Freeformgeo",
    };
    const url = serverUrl.web + "/VehicleController/getGeoFence";
    this.ajaxService.ajaxPostWithBody(url, body).subscribe(
      (res) => {
        if (res.length > 0) {
          this.geoFenceJsonAll = res;
          this.geoFenceJson = res;
          if (!this.selectedGeofenceVin || !this.selectedGeofenceVinData) {
            this.selectedGeofenceVin = this.geoFenceJson[0].vin;
            this.selectedGeofenceVinData = this.geoFenceJson[0];
          } else {
            for (let i = 0; i < this.geoFenceJson.length; i++) {
              if (
                this.selectedGeofenceVinData.Id == this.geoFenceJson[i].id &&
                this.selectedGeofenceVinData.zone == this.geoFenceJson[i].zone
              ) {
                this.selectedGeofenceVin = this.geoFenceJson[i].vin;
                this.selectedGeofenceVinData = this.geoFenceJson[i];
                break;
              }
            }
          }

        } else {
          this.commonService.presentToast("Create new geofence to show");
        }
        this.commonService.dismissLoader();
      },
      (err) => {
        this.commonService.dismissLoader();
        console.log(err);
      }
    );
  }
  async editGeoFenceModel(selectedVinData) {
    if (this.selectItem === true) {
      if (this.filterValue == "All") {
        localStorage.setItem("selectedZone", JSON.stringify(selectedVinData));
        const modal = await this.modalController.create({
          component: GeofenceModelPage,
          cssClass: this.directory != 'rtl' ? "geoFenceCreationModal" : "geoFenceCreationModal-rtl",
          componentProps: {
            mode: "edit",
            vin: selectedVinData.vin,
            vinData: selectedVinData,
          },
        });
        modal.onDidDismiss().then(() => this.ionViewWillEnter());
        return await modal.present();
      } else {
        this.commonService.presentToast(
          "press + icon to associate geo-fence to vehicle"
        );
      }
    } else {
      this.onLongPress("", selectedVinData);
    }
  }

  async geoFenceModel(selectedVin, selectedVinData) {

    this.selectedGeofenceVin = selectedVin;
    this.selectedGeofenceVinData = selectedVinData;

  }
  onLongPress($event, selectedVinData) {
    if (this.filterValue != "All") {
      this.selectItem = false;
      selectedVinData["clicked"] = true;
      if (
        document.getElementById(selectedVinData.id).style.background !==
        "lightgrey"
      ) {
        this.selectedZoneData.push(selectedVinData);
        document.getElementById(selectedVinData.id).style.background =
          "lightgrey";
      } else {
        for (let x = 0; x < this.selectedZoneData.length; x++) {
          if (this.selectedZoneData[x].id === selectedVinData.id) {
            this.selectedZoneData.splice(x, 1);
            break;
          }
        }
        selectedVinData.clicked = false;
        document.getElementById(selectedVinData.id).style.background = "white";
      }
    } else {
      this.commonService.presentToast(
        "Can't able to assign here!, Go to the vehicle and assign"
      );
    }
  }
  cancelSelected = function () {
    this.getGeoFenceData(this.zoneType);
    // this.selectItem = true;
    // for (let x = 0; x < this.selectedZoneData.length; x++) {
    //   document.getElementById(this.selectedZoneData[x].id).style.background = 'white';
    // }
    this.selectedZoneData = [];
  };

  async deleteSelectedGeoZone(data) {
    let selectedJsonFormat = {
      id: data.id,
      type: data.type,
      lanmark: data.zone,
    };
    let filter = [];
    filter = this.geoFenceJsonAll.filter((element) => {
      if (element.id == data.id && element.vins != "---") {
        return true;
      }
    });
    filter = this.associatedGeofenceList.filter(res => {
      if (res.id.split("-")[0] == data.id) {
        return 1;
      }
    })
    if (filter.length == 0) {
      this.selectedZoneData.push(data);
      const alert = await this.alertController.create({
        header: this.trans.instant("Delete"),
        backdropDismiss: false,
        message: this.trans.instant("Are you sure you want to delete this zone"),
        buttons: [
          {
            text: this.trans.instant("Cancel"),
            role: "cancel",
            handler: (data) => { },
          },
          {
            text: this.trans.instant("Ok"),
            handler: (data) => {
              var shape = {
                geoFence: "geozone",
                Circle: "geozone",
                road: "road",
                freeForm: "freeForm",
                landMark: "landMark",
              };
              var json = {
                id: selectedJsonFormat.id,
                zoneType: shape[selectedJsonFormat.type],
                companyID: localStorage.corpId,
                branchID: localStorage.corpId,
                userID: localStorage.userName,
                name: selectedJsonFormat.lanmark,
                macIp: ""
              };

              let zoneid = true;
              console.log(zoneid);
              let delzone = this.deletezone;
              delzone.filter(function (delzone: any) {
                if (selectedJsonFormat.id == delzone.id.split("-")[0]) {
                  zoneid = false;
                }
              });
              if (!zoneid) {
                this.commonService.presentToast(
                  "Please disassociate vehicles to delete zone"
                );
                return null;
              }

              const url = serverUrl.web + "/VehicleController/deleteGeoFence";
              this.ajaxService.ajaxDeleteWithBody(url, json).subscribe(
                (res) => {
                  console.log(res);
                  this.ionViewWillEnter();
                  this.commonService.presentToast("Your zone deleted successfully");
                  this.commonService.dismissLoader();
                  this.selectedZoneData = [];
                },
                (err) => {
                  this.commonService.dismissLoader();
                  console.log(err);
                  this.commonService.presentToast("Please try after some time");
                  this.selectedZoneData = [];
                }
              );
            },
          },
        ],
      });

      await alert.present();
    } else {
      if (this.entryLogin === "ATM") {
        this.commonService.presentAlert("Error", "Please disassociate ATM to delete zone");
      }
      else
        this.commonService.presentAlert("Error", "Please disassociate vehicles to delete zone");
    }
  }

  assignGeoToVehicle(assign, status) {
    const selectedId = [];
    let associateValue = "association";
    if (assign != undefined) {
      if (assign == true) {
        associateValue = "association";
      } else {
        associateValue = "disassociation";
      }
    }
    for (let i = 0; i < this.selectedZoneData.length; i++) {
      selectedId.push(this.selectedZoneData[i].Status);
    }
    if (selectedId.length == 0) {
      selectedId.push(status);
    }
    let selectedVin = JSON.parse(localStorage.getItem("selectedVin"));
    selectedVin = selectedVin.vin;
    const detailsForAssign = {
      vin: selectedVin,
      geoIds: selectedId,
      operation: associateValue,
    };
    const url = serverUrl.web + "/zone/geozone/vehicle/associate";
    this.ajaxService.ajaxPostWithString(url, detailsForAssign).subscribe(
      (res) => {
        console.log(res);
        this.succSaveZone(res, "Vehicle");
        // this.commonService.dismissLoader();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  succSaveZone(data, type) {
    if (data === "success") {
      let mode = "added";
      if (this.type === "edit" || type == "Vehicle") {
        mode = "updated";
      }
      this.ionViewWillEnter();
      this.commonService.presentToast(
        "Your," + type + " " + mode + " successfully"
      );
    } else {
      this.commonService.presentAlert("Error", type + " not added");
    }
  }
  async listAssociated(status) {
    let zonePlateNos = [];
    zonePlateNos = this.geoFenceJsonAll.filter((geo) => {
      if (geo.Status == status && geo.Vin !== null && geo.Vin !== "---")
        return true;
    });
    if (zonePlateNos.length != 0) {
      const modal = await this.modalController.create({
        component: GeofenceListModelPage,
        componentProps: {
          geozoneObject: zonePlateNos,
        },
      });
      modal.onDidDismiss().then(() => this.ionViewWillEnter());
      return await modal.present();
    } else {
      this.commonService.presentToast("No vehicles associated");
    }
  }
  async open() {
    if (localStorage.selectedVin != "undefined") {
      this.selectedVin = JSON.parse(localStorage.getItem("selectedVin"));
    }

    if (this.selectedVin != null) {
      const modal = await this.modalController.create({
        component: GeofenceModelPage,
        cssClass: this.directory != 'rtl' ? "geoFenceCreationModal" : "geoFenceCreationModal-rtl",
        componentProps: {
          mode: "add",
          vin: this.selectedVin.vin,
          vinData: this.selectedVin,
        },
      });
      modal.onDidDismiss().then(() => this.ionViewWillEnter());
      return await modal.present();
    } else {
      const modal = await this.modalController.create({
        component: GeofenceModelPage,
        cssClass: this.directory != 'rtl' ? "geoFenceCreationModal" : "geoFenceCreationModal-rtl",
        componentProps: {
          mode: "add",
        },
      });
      modal.onDidDismiss().then(() => this.ionViewWillEnter());
      return await modal.present();
    }
  }
  async ionViewDidEnter() { }
}
