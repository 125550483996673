import { Component, OnInit, ViewChild } from '@angular/core';
import { Platform, ModalController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ExportExcelService } from '../../services/export-excel.service';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { app, languageInitializer, serverUrl } from 'src/environments/environment';
import { AlertConfigurationModelComponent } from '../alert-configuration-model/alert-configuration-model.component';

@Component({
  selector: 'app-alert-configuration',
  templateUrl: './alert-configuration.component.html',
  styleUrls: ['./alert-configuration.component.scss'],
})
export class AlertConfigurationComponent implements OnInit {
  renderer: (row: number, column: any, value: string) => string;
  @ViewChild('alertConfig', { static: false }) myGrid: jqxGridComponent;
  source: { localdata: any; };
  dataAdapter: any;
  selectedRowIdx: any;
  selectedRow: any;
  columns: { text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; }[];
  directory: string = languageInitializer.directory;
  pdfdatas = [];
  localizationobj: {};
  pagesizeoption = ["5", "10", "13", "15", "20"];
  constructor(
    private ajaxService: AjaxService,
    private modalController: ModalController,
    private commonService: CommonService,
    private alertController: AlertController,
    private trans: TranslateService,
    public platform: Platform,
    private ete: ExportExcelService,
  ) { }
  head = ['Alert type', 'Plate no', 'Alert interval', 'Alert time', 'Alert range', 'Expiry date', 'Email address'];


  myGridOnRowSelect($event) {
    this.selectedRow = $event.args.row;
    console.log("this.selectedRow", this.selectedRow['Mobile Number'], this.selectedRow)

  }


  async openAddAlerts() {
    let modal = await this.modalController.create({
      component: AlertConfigurationModelComponent,
      cssClass: this.directory != 'rtl' ? "geoAssociation" : "geoAssociation-rtl",
      componentProps: {
        mode: 'new'
      }
    })
    modal.onDidDismiss().then(() => {
      // console.log("el",el);

      this.getDatas();
      this.myGrid.clearselection()
    });
    return await modal.present();

  }

  async openeditAlerts(mode) {
    if (mode == 'edit' && this.selectedRow) {
      let modal = await this.modalController.create({
        component: AlertConfigurationModelComponent,
        cssClass: this.directory != 'rtl' ? "geoAssociation" : "geoAssociation-rtl",
        componentProps: {
          mode: 'edit',
          selectedRow: this.selectedRow
        }
      })
      modal.onDidDismiss().then(() => {
        this.getDatas();
        this.myGrid.clearselection()
      });
      return await modal.present();
    } else {
      this.commonService.presentToast('Please select a row to edit');
    }
    if (mode == "multi") {
      let modal = await this.modalController.create({
        component: AlertConfigurationModelComponent,
        cssClass: this.directory != 'rtl' ? "geoAssociation" : "geoAssociation-rtl",
        componentProps: {
          mode: 'multi',
          tabledata: this.source.localdata
        }
      })
      modal.onDidDismiss().then(() => {
        this.getDatas();
        this.myGrid.clearselection()
      });
      return await modal.present();
    }

  }

  async opendeleteAlerts() {
    if (this.selectedRow) {
      const alert = await this.alertController.create({
        header: this.trans.instant('Are you sure?'),
        backdropDismiss: false,
        message: this.trans.instant("Do you want to delete your Record?. If you delete it, your changes will be lost."),
        buttons: [{
          text: this.trans.instant('Cancel'),
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: this.trans.instant('Ok'),
          handler: datas => {
            let data = {};
            data["companyId"] = localStorage.getItem('corpId');
            data["branchId"] = localStorage.getItem('corpId');
            data["userId"] = this.selectedRow["User"];
            data["alertType"] = this.selectedRow.alertType;
            data["companyRole"] = localStorage.getItem('companyRole');
            data["alertVin"] = this.selectedRow['Vehicle Details'];
            data["plateNo"] = this.selectedRow['Plate Number'];
            data["macIp"] = "";
            data["loginUser"] = localStorage.getItem('userName');

            const url = serverUrl.web + '/Alert/delete';
            this.ajaxService.ajaxPostWithString(url, data)
              .subscribe(res => {
                console.log("res", res);
                if (res == "persisted") this.commonService.presentToast("successfully deleted")
                else {
                  this.commonService.presentToast("something wrong please try later")
                  return
                }
                this.modalController.dismiss()
                let method = "alertconfigCacheByMultiVins";
                this.configalerts([{ vin: this.selectedRow["Vehicle Details"], plateNo: this.selectedRow['Plate Number'] }], method);
                this.getDatas();
              }, err => {
                this.modalController.dismiss()
                this.commonService.presentToast("Failed")
              });
          }
        }]
      });
      await alert.present();
    }
    else {
      this.commonService.presentToast('Please Select a Row to Delete');
      return "";
    }
  }

  configalerts(vindata, method) {
    var json = {};
    json["method"] = method;
    json["companyId"] = localStorage.corpId;
    json["multivin"] = true;
    json["userId"] = localStorage.userName
    if (vindata.length > 1) {
      let vin = ''
      for (let i = 0; i < vindata.length; i++) {
        if (vindata.length - 1 == i)
          vin += "\'" + vindata[i].vin + "\'"
        else
          vin += "\'" + vindata[i].vin + "\',"
      }
      json["vin"] = vin;
    } else {
      json["vin"] = "\'" + vindata[0].vin + "\'";
    }
    const url = serverUrl.Admin + '/api/alerts/config';
    this.ajaxService.ajaxPostWithString(url, json)
      .subscribe(res => {
      }, err => {
        this.commonService.presentToast("failed")
      });
  }

  getDatas() {
    let url = serverUrl.web + "/Alert/DCData"
    let data = {
      "compId": localStorage.corpId,
      "brnchId": localStorage.corpId,
      "userId": localStorage.userName,
      "Name": "towedAlert",
      "Where": `OVERSPEED,HARSHACCELERATION,Movement,STOP,WOKEUP,OPTIME,IDLE,FATIGUE,SOS,ENGINESTATUS,POWERCUT,POWERON,SEATBELT,PANIC,HARSHBRAKING,DRIFT,PanelAlarms,PreventiveMaintenance,ScheduledMaintenance,ANTITHEFT,LowBattery,TOWED,NOTRANSMISSION,FUEL FILLING,FUEL DRAIN,FUEL MAX THRESHOLD,FUEL MIN THRESHOLD,TEMPERATURE1,TEMPERATURE2,TEMPERATURE3,TEMPERATURE4,BTTEMPERATURE1,BTTEMPERATURE2,BTTEMPERATURE3,BTTEMPERATURE4,DOOROPEN,DOOROPEN BACK`,
      "SubQuery": "",
      "Header": { "Action": "gridViewChart", "Entity": "FixMe!" }
    }
    this.ajaxService.ajaxPostWithBody(url, data)
      .subscribe(res => {

        var detail = res;
        this.pdfdatas = [];
        for (var i = 0; i < detail.length; i++) {
          this.pdfdatas.push([detail[i].alertType, detail[i]["Plate Number"], detail[i]["Interval (Minutes)"], detail[i].Time, detail[i].Range, detail[i].Expiry, detail[i]["Email Address"]]);
        }
        this.source = { localdata: detail };
        this.dataAdapter = new jqx.dataAdapter(this.source);
        this.columns = [
          { text: this.trans.instant('Alert type'), datafield: 'alertType', cellsrenderer: this.renderer },
          { text: this.trans.instant('Plate no'), datafield: "Plate Number", cellsrenderer: this.renderer },
          { text: this.trans.instant('Alert interval'), datafield: 'Interval (Minutes)', cellsrenderer: this.renderer },
          { text: this.trans.instant('Alert time'), datafield: 'Time', cellsrenderer: this.renderer },
          { text: this.trans.instant('Alert range'), datafield: 'Range', cellsrenderer: this.renderer },
          { text: this.trans.instant('Expiry date'), datafield: 'Expiry', cellsrenderer: this.renderer },
          { text: this.trans.instant('Email address'), datafield: 'Email Address', cellsrenderer: this.renderer },
        ]
        this.myGrid.pagesizeoptions(this.pagesizeoption)
        if (app.entryPoint == "WFT")
          this.columns[1] = { text: this.trans.instant('Employee name'), datafield: "Plate Number", cellsrenderer: this.renderer }
        if (app.entryPoint == "ATM")
          this.columns[1] = { text: this.trans.instant('ATM ID'), datafield: "Plate Number", cellsrenderer: this.renderer }

        this.commonService.dismissLoader();


      })
  }

  duplicateDataPresisted() {
    this.renderer = (row: number, column: any, value: string) => {
      if (value == "" || null || undefined) {
        return "----";
      } else {
        if (column == 'Plate Number' && app.entryPoint == "WFT")
          value = value.split("@,@")[0] + ` (${value.split("@,@")[1]})`;
        return (
          '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' +
          value +
          "</span>"
        );
      }
    };
    this.source = { localdata: "" };
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.columns = [
      { text: this.trans.instant('Alert type'), datafield: 'alertType', cellsrenderer: this.renderer },
      { text: this.trans.instant('Plate no'), datafield: "Plate Number", cellsrenderer: this.renderer },
      { text: this.trans.instant('Alert interval'), datafield: 'Interval (Minutes)', cellsrenderer: this.renderer },
      { text: this.trans.instant('Alert time'), datafield: 'Time', cellsrenderer: this.renderer },
      { text: this.trans.instant('Alert range'), datafield: 'Range', cellsrenderer: this.renderer },
      { text: this.trans.instant('Expiry date'), datafield: 'Expiry', cellsrenderer: this.renderer },
      { text: this.trans.instant('Email address'), datafield: 'Email Address', cellsrenderer: this.renderer },
    ]
    if (app.entryPoint == "WFT")
      this.columns[1] = { text: this.trans.instant('Employee name'), datafield: "Plate Number", cellsrenderer: this.renderer }
    if (app.entryPoint == "ATM")
      this.columns[1] = { text: this.trans.instant('ATM ID'), datafield: "Plate Number", cellsrenderer: this.renderer }

  }

  async createPdf() {
    if (app.entryPoint == "WFT")
      this.head[1] = "Employee name"
    // For filtered rows
    const filteredRows = this.myGrid.getrows();
    const filteredData = filteredRows.map(row => {
      const obj = {};
      obj["Alert type"] = row.alertType;
      obj["Plate no"] = row["Plate Number"];
      obj["Alert interval"] = row["Interval (Minutes)"];
      obj["Alert time"] = row.Time;
      obj["Alert range"] = row.Range;
      obj["Expiry date"] = row.Expiry;
      obj["Email address"] = row["Email Address"];
      return obj;
    });
    //For filtered rows
    this.commonService.createPdf(this.head, this.pdfdatas, filteredData, "Alerts Configuration", "", "Alerts Configuration", null)
  }


  exportToExcel() {
    if (app.entryPoint == "WFT")
      this.head[1] = "Employee name"
    // For filtered rows
    const filteredRows = this.myGrid.getrows();
    const filteredData = filteredRows.map(row => {
      const obj = {};
      obj["Alert type"] = row.alertType;
      obj["Plate no"] = row["Plate Number"];
      obj["Alert interval"] = row["Interval (Minutes)"];
      obj["Alert time"] = row.Time;
      obj["Alert range"] = row.Range;
      obj["Expiry date"] = row.Expiry;
      obj["Email address"] = row["Email Address"];
      return obj;
    });
    //For filtered rows
    let reportData = {
      title: 'Alerts Configuration',
      data: this.pdfdatas,
      headers: this.head,
      data1: filteredData
    }
    this.ete.exportExcel(reportData);
  }


  ngOnInit() {
    const url = serverUrl.web + '/login/getPreferences?key=isBuzzerOption&companyId=' + localStorage.corpId;
    this.ajaxService.ajaxGetPerference(url)
      .subscribe(res => {
        if (res == "true") {
          this.commonService.buzzerShow = true;
        } else {
          this.commonService.buzzerShow = false;
        }
      })
    this.commonService.presentLoader()
    this.duplicateDataPresisted();
    this.getDatas();
    this.localizationobj = {
      groupsheaderstring: this.trans.instant("Drag a column and drop it here to group by that column"),
    };
  }

}
