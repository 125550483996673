import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Location } from '@angular/common';

@Component({
  selector: 'app-student-alert-report',
  templateUrl: './student-alert-report.page.html',
  styleUrls: ['./student-alert-report.page.scss'],
})
export class StudentAlertReportPage implements OnInit {
  icon = {
    sent: "checkmark-circle",
    notsent: "close-circle"
  }
  displayData =
    [
      {
        "StudentName": "Mohamed Jailani", "Contactno": "9962139969", "Alerts": [{
          type: "ABS(M)", status: "sent"
        }, {
          type: "SPFST(E)", status: "sent"
        }, {
          type: "BATAT(M)", status: "notsent"
        }
        ]
      }
      ,
      {
        "StudentName": "Mohamed Jailani", "Contactno": "9962139969", "Alerts": [{
          type: "ABS(M)", status: "sent"
        }, {
          type: "ABS(R)", status: "sent"
        }, {
          type: "ABS(T)", status: "sent"
        }, {
          type: "ABS(Y)", status: "sent"
        }, {
          type: "ABS(U)", status: "sent"
        }, {
          type: "ABS(I)", status: "sent"
        }, {
          type: "ABS(O)", status: "notsent"
        }, {
          type: "ABS(E)", status: "sent"
        }
        ]
      },
      {
        "StudentName": "Mohamed Jailani", "Contactno": "9962139969", "Alerts": [{
          type: "ABS(M)", status: "sent"
        }, {
          type: "ABS(R)", status: "sent"
        }, {
          type: "ABS(T)", status: "sent"
        }, {
          type: "ABS(Y)", status: "sent"
        }, {
          type: "ABS(U)", status: "sent"
        }, {
          type: "ABS(I)", status: "sent"
        }, {
          type: "ABS(O)", status: "sent"
        }, {
          type: "ABS(E)", status: "sent"
        }
        ]
      },
      {
        "StudentName": "Mohamed Jailani", "Contactno": "9962139969", "Alerts": [{
          type: "ABS(M)", status: "sent"
        }, {
          type: "ABS(R)", status: "sent"
        }, {
          type: "ABS(T)", status: "sent"
        }, {
          type: "ABS(Y)", status: "sent"
        }, {
          type: "ABS(U)", status: "sent"
        }, {
          type: "ABS(I)", status: "sent"
        }, {
          type: "ABS(O)", status: "sent"
        }, {
          type: "ABS(E)", status: "sent"
        }
        ]
      }
    ]
  commonData;
  displayCount: number = 30;
  // displayData: Array<any>;
  currentPage: number = 1;
  count = 10;
  constructor(
    private platform: Platform,
    private location: Location
  ) { }

  getBack() {
    this.location.back();
  }
  doInfinite(event) {
    setTimeout(() => {
      this.displayData.push(...this.commonData.slice(this.currentPage * this.displayCount, (this.currentPage + 1) * this.displayCount));
      this.currentPage++;
      event.target.complete();
      if (this.displayData.length == this.commonData.length) {
        event.target.disabled = true;
      }
    }, 500);
  }
  setDisplayData() {
    if (this.commonData.length > this.displayCount) {
      this.displayData = this.commonData.slice(0, this.displayCount);
    }
    else {
      this.displayData = this.commonData;
    }
  }
  ngOnInit() {
    this.commonData = this.displayData
    this.setDisplayData();
  }
  ngOnChanges() {
    this.commonData = this.displayData
    this.setDisplayData();
  }

}
