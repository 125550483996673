import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ShiftDetailsModelComponent } from './shift-details-model/shift-details-model.component';
import { languageInitializer, serverUrl, storageVariable } from 'src/environments/environment';
import { Platform, ModalController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { ExportExcelService } from '../../services/export-excel.service';

@Component({
  selector: 'app-shift-details',
  templateUrl: './shift-details.component.html',
  styleUrls: ['./shift-details.component.scss'],
})
export class ShiftDetailsComponent implements OnInit {

  @ViewChild('shiftDetails', { static: false }) myGrid: jqxGridComponent;
  directory = languageInitializer.directory
  pdfdatas = [];
  localizationobj: {};
  dataAdapter: any;
  selectedRow: any;
  source: { localdata: any; };
  columns: { text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; }[];
  renderer: (row: number, column: any, value: string) => string;
  head: any = ["Shift name", "Shift start time", "Shift end time", "Shift type"]

  constructor(
    private modalController: ModalController,
    private ajaxService: AjaxService,
    private commonService: CommonService,
    private alertController: AlertController,
    private trans: TranslateService,
    public platform: Platform,
    private ete: ExportExcelService,
  ) { }


  myGridOnRowSelect($event) {
    console.log($event)
    this.selectedRow = $event.args.row;
  }


  async openAddShiftDetails() {
    let modal = await this.modalController.create({
      component: ShiftDetailsModelComponent,
      cssClass: this.directory != 'rtl' ? "shiftDetails" : "shiftDetails-rtl",
      componentProps: {
        mode: 'new'
      }
    })
    modal.onDidDismiss().then(() => {
      this.myGrid.clearselection();
      this.getDatas();
    });
    return await modal.present();
  }

  async openEditShiftDetails(mode) {
    if (mode == 'edit') {
      let modal = await this.modalController.create({
        component: ShiftDetailsModelComponent,
        cssClass: this.directory != 'rtl' ? "shiftDetails" : "shiftDetails-rtl",
        componentProps: {
          mode: 'edit',
          selectedRow: this.selectedRow
        }
      })
      modal.onDidDismiss().then(() => {
        this.getDatas();
        this.myGrid.clearselection()
      });
      return await modal.present();
    }
  }

  async deleteShiftDetails() {
    if (this.selectedRow) {
      const alert = await this.alertController.create({
        header: 'Are you sure?',
        backdropDismiss: false,
        message: "Do you want to delete your Record?. If you delete it, your changes will be lost.",
        buttons: [{
          text: 'Cancel',
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: 'Ok',
          handler: datas => {
            let url = serverUrl.web + "/employe/delShiftDetails?id=" + this.selectedRow.shiftId
            this.ajaxService.ajaxDeleteWithString(url).subscribe(res => {
              console.log("res", res);

              if (res.statusText == "OK" && res.error.text != "failure") {
                this.commonService.presentToast("Deleted successfully")
                this.myGrid.clearselection();
                this.getDatas();
              }
              else if (res.error.text == "failure") {
                this.commonService.presentToast("Associated shift can't be deleted")
                this.myGrid.clearselection();
                this.getDatas();
              }
              else {
                this.commonService.presentToast("Failed")
              }
            })
          }
        }]
      });
      await alert.present();
    }
    else {
      this.commonService.presentToast('Please Select a Row to Delete');
      return "";
    }
  }





  getDatas() {
    let url = serverUrl.web + "/employe/getShiftDetails"
    let data = {
      "companyID": localStorage.corpId,
      "branchID": localStorage.corpId,
      "userId": localStorage.userName
    }
    this.ajaxService.ajaxPostWithBody(url, data)
      .subscribe(res => {
        var detail = res;
        this.pdfdatas = [];
        for (var i = 0; i < detail.length; i++)
          this.pdfdatas.push([detail[i]['shiftName'], detail[i]["shiftStartTime"], detail[i]["shiftEndTime"], detail[i]['shiftType']]);

        this.source = { localdata: detail };
        this.dataAdapter = new jqx.dataAdapter(this.source);
        this.columns = [
          { text: this.trans.instant('Shift Name'), datafield: 'shiftName', cellsrenderer: this.renderer },
          { text: this.trans.instant('Shift Start Time'), datafield: "shiftStartTime", cellsrenderer: this.renderer },
          { text: this.trans.instant('Shift End Time'), datafield: 'shiftEndTime', cellsrenderer: this.renderer },
          { text: this.trans.instant('Shift Type'), datafield: 'shiftType', cellsrenderer: this.renderer },
        ]
        this.commonService.dismissLoader();
      })
  }

  duplicateDataPresisted() {
    this.source = { localdata: "" };
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.columns = [
      { text: this.trans.instant('Shift Name'), datafield: 'shiftName', cellsrenderer: this.renderer },
      { text: this.trans.instant('Shift Start Time'), datafield: "shiftStartTime", cellsrenderer: this.renderer },
      { text: this.trans.instant('Shift End Time'), datafield: 'shiftEndTime', cellsrenderer: this.renderer },
      { text: this.trans.instant('Shift Type'), datafield: 'shiftType', cellsrenderer: this.renderer },
    ]
  }


  async createPdf() {
    this.commonService.createPdf(this.head, this.pdfdatas, null, "Shift", "", "Shift", null)
  }

  exportToExcel() {
    let shiftdata = {
      title: 'Shift',
      data: this.pdfdatas,
      headers: this.head
    }
    this.ete.exportExcel(shiftdata);
  }

  ngOnInit() {
    this.duplicateDataPresisted();
    this.getDatas();

    this.localizationobj = {
      groupsheaderstring: this.trans.instant("Drag a column and drop it here to group by that column"),
    }
  }

}
