import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { app } from 'src/environments/environment';
import { CommonService } from '../services/common.service';


@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})

export class TabsPage implements OnInit {
  @ViewChild('myTabs', { static: false }) myTabs;
  appName: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private platform: Platform,
    private commonService: CommonService
  ) { }
  status: string;
  gridViewUrl: string;
  mapViewUrl: string;
  selectedTab: string;
  alertsUrl: string;
  dashboardUrl: string;
  trackHistoryUrl: string;
  tabChanged() {
    this.selectedTab = this.myTabs.getSelected();
  }

  ngOnInit() {
    this.appName = app.appName;
    this.status = this.activatedRoute.snapshot.children[0].paramMap.get('type');
    this.dashboardUrl = "members/dashboard";
    this.mapViewUrl = "mapview/All";
    this.gridViewUrl = "gridview/All";
    this.alertsUrl = "alerts/" + this.status;
    this.trackHistoryUrl = "mapview/All";
  }

}

