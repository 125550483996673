import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AjaxService } from 'src/app/services/ajax.service';
import { AuthMapService } from 'src/app/services/auth-map.service';
import { CommonService } from 'src/app/services/common.service';
import { OpenlayerMapService } from 'src/app/services/openlayer-map.service';
import { companyReportData, serverUrl } from 'src/environments/environment';

@Component({
  selector: 'app-stop-report',
  templateUrl: './stop-report.component.html',
  styleUrls: ['./stop-report.component.scss'],
})
export class StopReportComponent implements OnInit {
  map: any;
  @Input() commonData: any;
  @Input() report: any;
  @Input() displayCount: number = 30;
  displayData: Array<any>;
  currentPage: number = 1;
  @ViewChild('mapElement', { static: false }) mapElement;
  sourceLocation: { "lat": number; "lng": number; };
  destinationLocation: { "lat": number; "lng": number; };
  mapView: boolean = true;
  reportData = [];
  reportName: any;
  trackLine: any;
  count: number = 10;
  constructor(
    private mapService: AuthMapService,
    private ajaxService: AjaxService,
    private commonService: CommonService
  ) {
    // if (localStorage.map === 'GoogleMap') {
    //   this.mapService = new GoogleMapService();
    // } else {
    this.mapService = new OpenlayerMapService();
    // }
  }

  toggleMapView = (data) => {
    this.commonService.presentLoader();
    this.mapService.clearLayers(this.map, [1, 2]);


    let location = {
      "lat": parseFloat(data["endLocation"].split(",")[0]),
      "lng": parseFloat(data["endLocation"].split(",")[1])
    }
    this.mapView = true;
    setTimeout(() => {
      this.commonService.dismissLoader();
      this.mapService.addMarker(this.map, location, 'stopreport', 'assets/vtstrackhisIcon/Idle.png');
    }, 1000)
    this.mapService.fitBounds(this.map, 1);

  }
  plotBackTrackingPath(finalPath) {
    this.commonService.dismissLoader();
    if (finalPath.length === 0) {
      this.commonService.presentToast('No data available');
      this.mapService.clearLayers(this.map, [1, 2]);
      this.mapView = false;
    } else {
      for (let i = 0; i < finalPath.length; i++) {
        finalPath[i]["lat"] = finalPath[i]["latitude"]
        finalPath[i]["lng"] = finalPath[i]["longitude"]
      }
      this.mapView = true;
      this.mapService.createLine(this.map, finalPath);
      this.mapService.fitBounds(this.map, 2);
    }
  }
  closeMap() {
    this.mapView = false;
  }
  loadMap = () => {
    setTimeout(() => {
      if (localStorage.map === "GoogleMap") {
        this.map = this.mapService.loadMap(this.mapElement.nativeElement, { lat: -25.344, lng: 131.036 }, true, false);
      } else {
        this.map = this.mapService.loadMap(this.mapElement.nativeElement, { lat: -25.344, lng: 131.036 }, true, false);
      }
      this.mapService.setCenter(this.map, { lat: 17.786351, lng: 78.090820 });
    })
  }

  getAddress(data) {
    const url = serverUrl.web + "/login/company/latlngtoaddress/" + data["endLocation"].split(",")[0] + "/" + data["endLocation"].split(",")[1] + "/" + localStorage.corpId + "/" + localStorage.lang;
    this.ajaxService.ajaxGetObject(url)
      .subscribe(res => {
        data.location = res;
      });

  }

  setDisplayData() {
    if (this.commonData.length > this.displayCount) {
      this.displayData = this.commonData.slice(0, this.displayCount);
    }
    else {
      this.displayData = this.commonData;
    }
  }

  doInfinite(event) {
    setTimeout(() => {
      this.displayData.push(...this.commonData.slice(this.currentPage * this.displayCount, (this.currentPage + 1) * this.displayCount));
      this.currentPage++;
      event.target.complete();
      if (this.displayData.length == this.commonData.length) {
        event.target.disabled = true;
      }
    }, 500);
  }
  ionViewWillEnter() {
  }
  ngOnInit() {
    this.commonService.dismissLoader()
    this.commonData = companyReportData.reportData
    this.reportData = this.commonData;
    this.reportName = this.report;
    this.mapView = false;
    this.loadMap();
    this.setDisplayData();
  }
  ngOnChanges() {

    this.setDisplayData();
  }

  getArabicLetters(letter) {

    return this.commonService.numberToArabic(letter)
  }
}
