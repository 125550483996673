import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { IonThumbnail, Platform } from "@ionic/angular";
import { AjaxService } from "src/app/services/ajax.service";
import { AuthMapService } from "src/app/services/auth-map.service";
import { CommonService } from "src/app/services/common.service";
import { GoogleMapService } from "src/app/services/google-map.service";
import { OpenlayerMapService } from "src/app/services/openlayer-map.service";
import { serverUrl, storageVariable, languageInitializer } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { TranslateConfigService } from "../../services/translate-config.service";

@Component({
  selector: "app-vts-geofence-map-selected",
  templateUrl: "./vts-geofence-map-selected.component.html",
  styleUrls: ["./vts-geofence-map-selected.component.scss"],
})
export class VtsGeofenceMapSelectedComponent implements OnInit {
  @ViewChild("mapElement", { static: false }) mapElement;
  @Input() geofenceAllDate;
  @Input() selectedGeofenceVin;
  @Input() selectedGeofenceVinData;
  @Input() filterValue;
  unSelectedGeofenceVinData;
  layerModal = false;
  @Output() geofenceClear = new EventEmitter();
  companyAllVins = [];
  map: any;
  zoneType: string = "prefer";
  zonePlateNos: any = [];
  directory: string = languageInitializer.directory;
  constructor(
    public mapService: AuthMapService,
    public gMapService: GoogleMapService,
    public olMapService: OpenlayerMapService,
    public ajaxService: AjaxService,
    public commonService: CommonService,
    public platform: Platform,
    public translateConfigService: TranslateConfigService,
    public trans: TranslateService
  ) {
    this.mapService = new OpenlayerMapService();
  }
  switchMap() {
    this.layerModal = !this.layerModal
    this.map.getLayers().item(3).setVisible(this.layerModal);
    this.map.getLayers().item(0).setVisible(!this.layerModal);
  }
  createMap() {
    if (!this.map) {
      this.map = this.mapService.loadMap(
        this.mapElement.nativeElement,
        { lat: 24.440099, lng: 46.843498 },
        false,
        false
      );
      this.mapService.setCenter(this.map, { lat: 24.440099, lng: 46.843498 });
    }
  }

  disassociateGeozone(data) {
    const detailsForAssign = {
      vin: data.Vin,
      geoIds: [data.Status],
      operation: "disassociation",
    };
    const url = serverUrl.web + "/zone/geozone/vehicle/associate";
    this.ajaxService.ajaxPostWithString(url, detailsForAssign).subscribe(
      (res) => {
        this.succSaveZone(res, data.Vin);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  succSaveZone(data, vin) {
    if (data === "success") {
      this.commonService.presentToast(
        "Your vehicle disassociated successfully"
      );
      this.geofenceClear.emit();
    } else {
      this.commonService.presentAlert("Error", "Vehicle not deleted");
    }
  }

  ngOnChanges() {
    if (this.selectedGeofenceVinData) {
      this.companyAllVins = Object.values(
        storageVariable.dashboardData.liveDatas
      );
      let zoneVin =
        this.selectedGeofenceVinData.vins == undefined
          ? []
          : this.selectedGeofenceVinData.vins != "---"
            ? this.selectedGeofenceVinData.vins.split(",")
            : [];
      this.filterValue = this.filterValue;
      this.unSelectedGeofenceVinData =
        zoneVin.length == 0 ? this.companyAllVins : [];
      this.zonePlateNos = [];
      for (let i = 0; i < zoneVin.length; i++) {
        this.unSelectedGeofenceVinData = this.companyAllVins.filter(
          (data: any) => {
            if (data.vin == zoneVin[i]) {
              this.zonePlateNos.push(data);
            } else {
              return true;
            }
          }
        );
        this.companyAllVins = this.unSelectedGeofenceVinData;
      }
      if (this.map) this.mapService.clearLayers(this.map, [1, 2]);
      else this.createMap();
      if (this.selectedGeofenceVinData.type == "geoFence") {
        this.createCircle();
      } else if (this.selectedGeofenceVinData.type == "road") {
        this.createRoadForm();
      } else if (this.selectedGeofenceVinData.type == "freeForm") {
        this.createFreeForm();
      } else if (this.selectedGeofenceVinData.type == "landMark") {
        this.createLandMark();
      }
    }
  }
  createLandMark() {
    let data = this.selectedGeofenceVinData.latlng;
    let latLng = {
      lat: parseFloat(data.split(",")[0]),
      lng: parseFloat(data.split(",")[1]),
    };
    this.mapService.addMarker(
      this.map,
      latLng,
      "geoFence",
      "assets/vtstrackhisIcon/Idle.png"
    );
  }
  createRoadForm() {
    let data = this.selectedGeofenceVinData.latlngs;
    let latLngData = [];
    for (let i in data) {
      var latlng = data[i];
      latLngData.push({
        latitude: latlng.split(",")[0],
        longitude: latlng.split(",")[1],
      });
    }

    this.mapService.setRoadLineString(this.map, latLngData);
  }

  createFreeForm() {
    let data = this.selectedGeofenceVinData.latlngs;
    let latLngData = [];
    for (let i in data) {
      var latlng = data[i];
      latLngData.push({
        latitude: latlng.split(",")[0],
        longitude: latlng.split(",")[1],
      });
    }
    if (data[0])
      latLngData.push({
        latitude: data[0].split(",")[0],
        longitude: data[0].split(",")[1],
      });
    else {
      latLngData.push({
        latitude: data[1].split(",")[0],
        longitude: data[1].split(",")[1],
      });
    }
    this.mapService.setRoadLineString(this.map, latLngData);
  }

  createCircle() {
    let selectValue = "Prefered";
    let latLng;
    let range;
    if (this.selectedGeofenceVinData.area === "Restricted") {
      selectValue = "Restricted";
    }
    const detailsString = this.selectedGeofenceVinData.latlng;
    const getLatLng = detailsString.split("|");
    const southwest = getLatLng[0].split(",");
    const northeast = getLatLng[1].split(",");
    const south = parseFloat(southwest[0].split(",")[0]);
    const west = parseFloat(southwest[1].split(",")[0]);
    const north = parseFloat(northeast[0].split(",")[0]);
    const east = parseFloat(northeast[1].split(",")[0]);
    latLng = { lat: south, lng: west };
    range = this.calculateRadius(north, east, south, west);
    this.mapService.addMarker(
      this.map,
      latLng,
      "geoFence",
      "assets/vtstrackhisIcon/Idle.png"
    );
    this.mapService.createCircle(this.map, latLng, range, selectValue);
    this.mapService.fitBounds(this.map, 2);
  }

  calculateRadius = function (south, west, north, east) {
    const R = 6378.137; // Radius of earth in KM
    const dLat = ((north - south) * Math.PI) / 180;
    const dLon = ((east - west) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((south * Math.PI) / 180) *
      Math.cos((north * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return Math.round(d * 1000);
  };
  ngOnInit() {
    setTimeout(() => {
      this.createMap();
    }, 1000);
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
  }

  assignGeoToVehicle(data) {
    let selectedId = [];
    let associateValue = "association";
    selectedId.push(this.selectedGeofenceVinData.id.toString());
    let selectedVin;
    selectedVin = data.vin;
    const detailsForAssign = {
      vin: selectedVin,
      geoIds: selectedId,
      operation: associateValue,
    };
    const url = serverUrl.web + "/zone/geozone/vehicle/associate";
    this.ajaxService.ajaxPostWithString(url, detailsForAssign).subscribe(
      (res) => {
        // this.succSaveZone(res, 'Vehicle');
        this.commonService.presentToast(
          "Your geofence accociated successfully"
        );
        this.geofenceClear.emit();
        this.commonService.dismissLoader();
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
