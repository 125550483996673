import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-alertshow',
  templateUrl: './alertshow.component.html',
  styleUrls: ['./alertshow.component.scss'],
})
export class AlertshowComponent implements OnInit {
  buttonText = "Go To PlayStore";
  constructor(
    private platform: Platform, private commonService: CommonService) { }
  iosAndroid() {
    if (this.platform.is('android')) {
      console.log('This is running on an Android device');
      this.buttonText = "Go To PlayStore";
      window.open(this.commonService.androidLink)
    } else if (this.platform.is('ios')) {
      console.log('This is running on an iOS device');
      this.buttonText = "Go To AppStore";
      window.open(this.commonService.iosLink)
    }
  }
  ngOnInit() {

  }

}
