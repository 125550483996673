import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { outputs } from '@syncfusion/ej2-angular-navigations/src/accordion/accordion.component';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { CommonService } from 'src/app/services/common.service';
import { storageVariable } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-group-tree-structure',
  templateUrl: './group-tree-structure.component.html',
  styleUrls: ['./group-tree-structure.component.scss'],
})
export class GroupTreeStructureComponent implements OnInit {
  @ViewChild('myTree', { static: false }) myTree: jqxTreeComponent;
  // dashboardJson: any[] = Object.values(storageVariable.dashboardData.liveDatas);
  dashboardJson = [] ;
  filterData: any = {};
  treeStructureObject: any = [];
  treeStructureJson: any = [];
  dataAdapter: any;
  onCheck: boolean = false;

  @Input() vin;
  @Input() alerts;
  @Input() handleInput;
  storageVar = storageVariable.upDatedJsonData
  source = {
    datatype: 'json',
    datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }],
    id: 'id',
    localdata:this.treeStructureJson
  };
  records: any;
  search: any;
  @Output() selectedVinDataForanAlytics = new EventEmitter();
  constructor(
    private modalController: ModalController,
    private commonService: CommonService,
    private trans: TranslateService
  ) { }

  closeData() {
    this.myTree.uncheckAll();
    this.selectedVinDataForanAlytics.emit({ plateNoData: [], plateVin: [] })
    this.modalController.dismiss({ plateNoData: [], plateVin: [] })
    this.commonService.showFilterView = false;//thas
  }
  exitSelectionPage() {
    var sortPlateNo = this.myTree.getCheckedItems();
    console.log(sortPlateNo)
    if (window.location.hash != "#/tabs/gridview/All" || !window.location.hash.includes("tabs/gridview/"))
    for (let pNo of sortPlateNo) {
      if (pNo.value == "Select all") {
       this.commonService.reportvin="all"
      }
    }
    var plateNoData = [];
    let plateVin = [];
    for (let pNo of sortPlateNo) {
      if (pNo.value !== pNo.label) {
        plateNoData.push(pNo.value)
        plateVin.push(pNo.label)
      }
    }
    this.selectedVinDataForanAlytics.emit({ plateNoData, plateVin })
    this.modalController.dismiss({ plateNoData, plateVin })

  }
  // searchgroup() {
  //   this.commonService.groupTreeFilterSearch(this.myTree, this.search)
  //   // var foundItem = 0;
  //   // var items = this.myTree.getItems();
  //   // var searchedValue = this.search.toLowerCase();
  //   // for (var i = 0; i < items.length; i++) {
  //   //   if (items[i].label.toLowerCase().indexOf(searchedValue) == 0) {

  //   //     this.myTree.expandItem(items[i].parentElement);
  //   //     this.myTree.selectItem(items[i]);
  //   //     if (foundItem != i) {
  //   //       this.myTree.collapseAll();
  //   //     }
  //   //     this.myTree.expandItem(items[i].parentElement);
  //   //     foundItem = i;
  //   //     break;
  //   //   }
  //   // };
  // };
  searchgroup() {
    const searchedValue = (this.alerts !== 'alertsShow') ? this.search.trim().toLowerCase() : this.handleInput;

    let data;
    if (this.myTree != undefined) {
        data = this.myTree.getItems();
    } else {
        return;
    }

    let foundItem = [];
    let expandedParents = new Set(); 

    data.forEach(res => {
        const labelText = res.label.toLowerCase();
        const textDiv = res.titleElement[0]; 

        textDiv.querySelectorAll('.highlightedText').forEach(span => {
            span.outerHTML = span.innerText;
        });

        if (searchedValue !== '' && labelText.includes(searchedValue)) {
            foundItem.push(res.element);
            this.myTree.expandItem(res.parentElement);
            expandedParents.add(res.parentElement); 

            textDiv.innerHTML = textDiv.innerHTML.replace(
                new RegExp(searchedValue, 'gi'),
                match => `<span class="highlightedText" style="font-weight: bold;">${match}</span>`
            );
        }
    });
    
  if (foundItem.length > 0) {
    if(foundItem[0].id != -1){
      this.myTree.selectItem(foundItem[0]);
      this.myTree.ensureVisible(foundItem[0]);
    }
  }

  if (searchedValue === '') {
    for (const res of data) {
        foundItem = res; 
        this.myTree.expandAll();
        this.myTree.selectItem(null);
        this.myTree.ensureVisible(res.element);
        break;
    }
}
}


  renderPlateNo() {
    this.treeStructureJson.push(
      {
        id: -1,
        parentid: null,
        text: this.trans.instant("Select all"),
        value: "Select all"
      })
      
      this.treeStructureObject['Select All']={
        id: -1,
        parentid: null,
        text: "Select all",
        value: "Select all"
      }
    let grpJsonId = 1;
    for (let i = 0; i < this.dashboardJson.length; i++) {
      let groupArray: any = [];
      groupArray = this.dashboardJson[i].group == null || this.dashboardJson[i].group == undefined || this.dashboardJson[i].group == "" ? ["NoneGroup"] : this.dashboardJson[i].group.split(",")
      let filterTextFormat = ""
      for (let j = 0; j < groupArray.length; j++) {
        filterTextFormat += groupArray[j]
        if (this.filterData[filterTextFormat] == undefined) {
          this.filterData[filterTextFormat] = grpJsonId;
          grpJsonId++;
        }
        if (groupArray.length - 1 != j)
          filterTextFormat += ","
      }
      // groupArray = this.dashboardJson[i].group == null ? "None group" : this.dashboardJson[i].group
      this.treeStructureObject[this.dashboardJson[i].plateNo] = {
        id: grpJsonId.toString(),
        parentid: this.filterData[groupArray].toString(),
        text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
        value: this.dashboardJson[i].vin
      }

      this.treeStructureJson.push({
        id: grpJsonId.toString(),
        parentid: this.filterData[groupArray].toString(),
        text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
        value: this.dashboardJson[i].vin
      })

      grpJsonId++;
    }
    for (let i = 0; i < Object.keys(this.filterData).length; i++) {
      let loopedArry: any = Object.keys(this.filterData)[i].split(",").length - 1;
      loopedArry = Object.keys(this.filterData)[i].split(",")[loopedArry]
      var popData = Object.keys(this.filterData)[i].split(",")
      popData.pop()
      this.treeStructureObject[loopedArry] = {
        id: Object.values(this.filterData)[i].toString(),
        parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
        text: loopedArry,
        value: loopedArry
      }

      this.treeStructureJson.push({
        id: Object.values(this.filterData)[i].toString(),
        parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
        text: loopedArry,
        value: loopedArry
      })
    }

    
    this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
    this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);
    // this.records = [{id:0,items:this.records, label:'Select All',parent:null, parentid:-1,text:'Select All', value:'Select All',uid:0} ]
    // console.log(" this.records", this.records);
    
    // if (this.vin != "Add")
    //   setTimeout(res => {
    //     for (let i = 0; i < this.vin.length; i++) {
    //       let a = document.getElementById(this.treeStructureObject[this.storageVar.liveDatas[this.vin[i].plateNo]].id)
    //     }
    //   }, 3000)
  }
  
  
  disableTreeDrag() {
    if (this.myTree) {
      this.myTree.allowDrag(false);
    }
  }
  ngAfterViewInit(): void {
    this.myTree.expandAll();
    this.myTree.allowDrag(false);
    if (this.vin != "" && (this.vin != "Add" && this.alerts != "alertsShow")) {
      for (let i = 0; i < this.vin.length; i++) {
        let selectRow = document.getElementById(this.treeStructureObject[this.storageVar.liveDatas[this.vin[i]].plateNo].id)
        this.myTree.checkItem(selectRow, true);
      }
    }
    setTimeout(() => {
      //this.disableTreeDrag();
    }, 1000);
  }
  ngOnInit() {
    let a: any[] = Object.values(storageVariable.dashboardData.liveDatas);
    this.dashboardJson = a
      .filter(item => !item['warrantyExpiry'])
      .map(item => ({ ...item }));
    this.renderPlateNo();
    this.commonService.dismissLoader();

  }
  ngOnChanges() {
    if (this.alerts == 'alertsShow') {
      this.searchgroup();
    }
  }



  SelectTree(data) {
    if (this.alerts == 'alertsShow') {
      setTimeout(() => {
        this.onCheck = true;
      }, 700);
    }
    if (this.onCheck) {
      var sortPlateNo = this.myTree.getCheckedItems();
      this.commonService.plateNoALert = sortPlateNo;
    }
  }


}
