import { stringify } from '@angular/compiler/src/util';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { storageVariable } from 'src/environments/environment';

@Component({
  selector: 'app-rac',
  templateUrl: './rac.component.html',
  styleUrls: ['./rac.component.scss'],
})
export class RACComponent implements OnInit {

  // @Input() rac;
  token;
  sanToken: SafeResourceUrl;
  dashboardJson = [];
  treeStructureObject: any = [];
  treeStructureJson: any = [];
  dataAdapter: any; 
  filterData: any = {};
  records: any;
  source = {
    datatype: 'json',
    datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }, { name: 'disabled' }],
    id: 'id',
    localdata: this.treeStructureJson
  };

  constructor(private modalController: ModalController,
    // private navParams: NavParams,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private router: Router,
  ) {

  }

  closeModel() {
    this.modalController.dismiss();
  }
  sanitizedToken(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.token);
  }
  ionViewWillEnter() {
    console.log("jsbdjb");

  }


  ngOnInit() {
    // const racParam = this.navParams.get('rac');
    // if (racParam) {
    //   this.token = 'https://ge-fleetonqa.thegoldenelement.com/#/airportadmin/' + racParam;
    //   this.sanToken = this.sanitizedToken();
    // } else {
    //   console.error('Invalid or missing rac parameter');
    // }
    // const racParam = this.navParams.get('rac');
    // if (this.commonService.RACres) {
      let a: any[] = Object.values(storageVariable.dashboardData.liveDatas);
      this.dashboardJson = a
        .filter(item => !item['warrantyExpiry'])
        .map(item => ({ ...item }));
        localStorage.setItem("records", JSON.stringify(this.dashboardJson));

      this.renderPlateNo();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(localStorage.pageSelector == 'Maintenance'){
          if (window.location.origin == "https://mvt.thegoldenelement.com") {
            this.token = 'https://mvt.thegoldenelement.com/fm/#/fm';
          } else if (window.location.origin == "https://mrac.thegoldenelement.com") {
            this.token = 'https://mrac.thegoldenelement.com/fm/#/fm';
          }else if (window.location.origin == "https://arabiantracking.net") {
            this.token = 'https://arabiantracking.net/fm/#/fm';
          }else if (window.location.origin == "https://ninja-test.thegoldenelement.com") {
            this.token = 'https://ninja-test.thegoldenelement.com/fm/#/fm';
          } else {
            this.token = 'https://qa-one.thegoldenelement.com/fm/#/fm';
          }
          this.sanToken = this.sanitizedToken();
          this.commonService.presentLoader();
          setTimeout(() => {
            this.commonService.dismissLoader();
          }, 5000);
        }
        if(localStorage.pageSelector == 'newreport'){
          if (window.location.origin == "https://mvt.thegoldenelement.com") {
            this.token = 'https://mvt.thegoldenelement.com/reports/#/newreport';
          } else if (window.location.origin == "https://mrac.thegoldenelement.com") {
            this.token = 'https://mrac.thegoldenelement.com/reports/#/newreport';
          }else if (window.location.origin == "https://arabiantracking.net") {
            this.token = 'https://arabiantracking.net/reports/#/newreport';
          }else if (window.location.origin == "https://ninja-test.thegoldenelement.com") {
            this.token = 'https://ninja-test.thegoldenelement.com/reports/#/newreport';
          } else {
            this.token = 'https://qa-one.thegoldenelement.com/reports/#/newreport';
          }
          this.sanToken = this.sanitizedToken();
          this.commonService.presentLoader();
          setTimeout(() => {
            this.commonService.dismissLoader();
          }, 5000);
        }
        }
    });
    // } else {
    // console.error('Invalid or missing rac parameter');
    // }

  }
  renderPlateNo() {
    this.treeStructureJson.push(
      {
        id: -1,
        parentid: null,
        text: "Select all",
        value: "Select all"
      })

    this.treeStructureObject['Select All'] = {
      id: -1,
      parentid: null,
      text: "Select all",
      value: "Select all"
    }
    let grpJsonId = 1;
    for (let i = 0; i < this.dashboardJson.length; i++) {
      let groupArray: any = [];
      groupArray = this.dashboardJson[i].group == null || this.dashboardJson[i].group == undefined || this.dashboardJson[i].group == "" ? ["NoneGroup"] : this.dashboardJson[i].group.split(",")
      let filterTextFormat = ""
      for (let j = 0; j < groupArray.length; j++) {
        filterTextFormat += groupArray[j]
        if (this.filterData[filterTextFormat] == undefined) {
          this.filterData[filterTextFormat] = grpJsonId;
          grpJsonId++;
        }
        if (groupArray.length - 1 != j)
          filterTextFormat += ","
      }
      // groupArray = this.dashboardJson[i].group == null ? "None group" : this.dashboardJson[i].group
      this.treeStructureObject[this.dashboardJson[i].plateNo] = {
        id: grpJsonId.toString(),
        parentid: this.filterData[groupArray].toString(),
        text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
        value: this.dashboardJson[i],
        disabled: false
      }

      this.treeStructureJson.push({
        id: grpJsonId.toString(),
        parentid: this.filterData[groupArray].toString(),
        text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
        value: this.dashboardJson[i],
        disabled: false
      })

      grpJsonId++;
    }


    for (let i = 0; i < Object.keys(this.filterData).length; i++) {
      let loopedArry: any = Object.keys(this.filterData)[i].split(",").length - 1;
      loopedArry = Object.keys(this.filterData)[i].split(",")[loopedArry]
      var popData = Object.keys(this.filterData)[i].split(",")
      popData.pop()
      this.treeStructureObject[loopedArry] = {
        id: Object.values(this.filterData)[i].toString(),
        parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
        text: loopedArry,
        value: loopedArry,
        disabled: false
      }

      this.treeStructureJson.push({
        id: Object.values(this.filterData)[i].toString(),
        parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
        text: loopedArry,
        value: loopedArry,
        disabled: false
      })
    }


    this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
    this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);
    
  }

}
